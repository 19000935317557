<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">COURSE </span> UPLOADS
    </PageHeader>

    <v-row>
      <v-col cols="12">
        <v-card tile>
          <v-card-text>
            <v-form ref="courseHeader">
              <v-row>
                <v-col cols="12" lg="4">
                  <v-autocomplete
                    :loading="loadProgram"
                    v-model="program"
                    :items="itemsProgram"
                    @keyup="searchProgram"
                    clearable
                    class="rounded-0"
                    hide-details="auto"
                    hide-selected
                    item-text="program_name_certificate"
                    item-value="id"
                    label="Search Program"
                    outlined
                    :rules="[(v) => !!v || 'Program is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title v-if="loadProgram">
                          <v-progress-circular
                            color="primary"
                            indeterminate
                          ></v-progress-circular>
                          <span class="ml-5">
                            Searching
                            <strong>Program...</strong>
                          </span>
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          No
                          <strong>Record(s) Found...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="2">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :items="basket_campus"
                    item-value="id"
                    item-text="campus_name"
                    @click.once="fetchCampus"
                    class="rounded-0"
                    :loading="iscampusLoaded"
                    hide-details="auto"
                    outlined
                    label="Campus"
                    v-model="campus"
                    :rules="[(v) => !!v || 'Campus is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Campus...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="iscalendarLoaded"
                    v-model="calendar"
                    :items="basket_calendar"
                    class="rounded-0"
                    :item-text="
                      (item) =>
                        `${item.academic_calendar} ${
                          item.current ? ' Current' : ''
                        }`
                    "
                    item-value="id"
                    outlined
                    label="Calendar"
                    @click.once="fetchCalendar"
                    hide-details="auto"
                    :rules="[(v) => !!v || 'Calendar is required...']"
                  >
                    <template v-slot:selection="{ item }">
                      {{
                        `${item.academic_calendar} ${
                          item.current ? " Current" : ""
                        } `
                      }}
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Calendar(s)...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="issemesterLoaded"
                    @click.once="fetchSemester"
                    v-model="semester"
                    :items="basket_semester"
                    item-value="id"
                    item-text="semester"
                    class="rounded-0"
                    outlined
                    label="Semester"
                    hide-details="auto"
                    hide-selected
                    :rules="[(v) => !!v || 'Semester is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Semesters...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-slide-y-transition>
      <v-row v-if="showCourses">
        <v-col>
          <v-card>
            <v-card-title color="cyan lighten-5">
              <v-btn
                color="primary"
                class="secondary--text font-weight-bold mx-1"
                @click="publishresultBtn"
                :disabled="selected.every((e) => e.is_published)"
              >
                Publish
              </v-btn>
              <v-btn
                color="secondary"
                class="primary--text font-weight-bold"
                @click="unpublishresultBtn"
                :disabled="selected.every((e) => !e.is_published)"
              >
                Unpublish
              </v-btn>
            </v-card-title>
            <div
              class="body-1 text-center py-3"
              v-if="getters_lecturer_upload.length === 0"
            >
              <span>No Information Available</span>
            </div>
            <v-tabs
              center-active
              grow
              class="mt-5"
              v-else
              v-model="tab"
              align-with-title
            >
              <v-tab
                v-for="(details, level) in getters_lecturer_upload"
                :key="level"
                class="pa-1"
                active-class="subtitle-1 primary secondary--text font-weight-bold"
              >
                LEVEL {{ level }}
              </v-tab>
              <v-tabs-items v-model="tab">
                <v-divider></v-divider>
                <v-tab-item
                  v-for="(details, level) in getters_lecturer_upload"
                  :key="level"
                >
                  <v-data-table
                    :headers="headers"
                    :items="details"
                    class="elevation-1"
                    item-key="id"
                    hide-default-footer
                    show-select
                    v-model="selected"
                    fixed-header
                    @toggle-select-all="selectAllToggle"
                  >
                    <template
                      v-slot:item.data-table-select="{
                        item,
                        isSelected,
                        select,
                      }"
                    >
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <div v-on="on">
                            <v-simple-checkbox
                              :disabled="item.upload_script === false"
                              :readonly="item.upload_script === false"
                              v-on="on"
                              v-bind="attrs"
                              :value="isSelected"
                              @input="select($event)"
                              color="primary"
                            ></v-simple-checkbox>
                          </div>
                        </template>
                        <span v-if="!item.upload_script">
                          Unuploaded Result Cannot Be Published
                        </span>
                        <span
                          v-else-if="item.upload_script && item.is_published"
                        >
                          Result Is Already Published
                        </span>
                        <span v-else> Result Can Be Published</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.upload_script="{ item }">
                      <v-chip v-if="item.upload_script" color="success">
                        Submitted
                        <v-icon right> mdi-check-circle </v-icon>
                      </v-chip>
                      <v-chip v-else color="error">
                        Not Submitted <v-icon right> mdi-close-circle </v-icon>
                      </v-chip>
                    </template>
                    <template #item.is_published="{ item }">
                      <v-switch
                        v-model="item.is_published"
                        :label="`${item.is_published ? 'Closed' : 'Open'}`"
                        disabled
                      >
                      </v-switch>
                    </template>
                    <template #item.view_details="{ item }">
                      <v-btn @click="viewDetails(item)" dark right large icon>
                        <v-icon dark class="primary--text">
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                  <v-card v-if="items.length" v-intersect="intersected">
                    <v-progress-linear
                      :indeterminate="true"
                      v-if="loadingScroll"
                    ></v-progress-linear>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>

    <v-row>
      <v-col>
        <v-overlay :absolute="absolute" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>

    <v-navigation-drawer
      v-model="script"
      fixed
      :permanent="script"
      right
      width="75%"
      clipped
      class="shadow"
    >
      <div class="mt-15 d-flex justify-space-between">
        <v-spacer></v-spacer>
        <v-btn class="ma-2" @click="script = false" icon>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <CourseHistory v-if="script" />
    </v-navigation-drawer>
  </v-container>
</template>
<script>
  import PageHeader from "@/components/slots/PageHeader";
  import { getCurrentInstance, provide, reactive, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import Response from "@/components/ActionResponse/Response";
  import CourseHistory from "@/components/Faculty/CourseHistory.vue";

  export default {
    components: { PageHeader, Response, CourseHistory },
    setup() {
      const vm = getCurrentInstance();
      const {
        getProgram,
        getSemester,
        getCalendar,
        getCampus,
        signOut,
        getLecturerUpload,
        publishResult,
        unpublishResult,
      } = useActions([
        "getProgram",
        "getSemester",
        "getCalendar",
        "getCampus",
        "getCampus",
        "signOut",
        "getLecturerUpload",
        "publishResult",
        "unpublishResult",
      ]);

      const {
        getters_program,
        getters_semester,
        getters_calendar,
        getters_campus,
        getters_abilities,
        user,
        getters_lecturer_upload,
      } = useGetters([
        "getters_program",
        "getters_semester",
        "getters_calendar",
        "getters_campus",
        "getters_abilities",
        "user",
        "getters_lecturer_upload",
      ]);

      const courseHeader = reactive({
        tab: null,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        actionResponse: false,
        iscalendarLoaded: false,
        issemesterLoaded: false,
        iscampusLoaded: false,
        loadedProgram: true,
        program: "",
        calendar: "",
        semester: "",
        campus: "",
        basket_calendar: [],
        basket_semester: [],
        basket_campus: [],
        loadProgram: false,
        overlay: false,
        absolute: true,
        resultBasket: [],
        showCourses: false,
        itemsProgram: [],
        color: null,
      });

      const {
        showCourses,
        msgHeader,
        msgBody,
        msgIcon,
        actionResponse,
        itemsProgram,
        loadProgram,
        basket_calendar,
        basket_semester,
        basket_campus,
        program,
        calendar,
        semester,
        campus,
        iscalendarLoaded,
        issemesterLoaded,
        iscampusLoaded,
        overlay,
        color,
      } = toRefs(courseHeader);

      const headerTable = reactive({
        headers: [
          {
            text: "COURSE",
            value: "course.coursecode_coursename",
          },
          { text: "INTAKE", value: "header.intake.intake" },
          { text: "SESSION", value: "session.session" },
          { text: "LECTURER", value: "user.title_name" },
          { text: "STATUS", value: "upload_script" },
          {
            text: "PUBLISHED",
            value: "is_published",
            align: "center",
            sortable: false,
          },
          {
            text: "VIEW SCORES",
            value: "view_details",
            align: "center",
            sortable: false,
          },
        ],
        selected: [],
        disableCount: 0,
        totalCount: 0,
        page: 1,
        isLoaded: false,
        isIntersecting: false,
        items: [],
        loadingScroll: false,
        scriptId: 0,
        gradeId: 0,
        script: false,
        courseDetails: {},
      });

      const {
        selected,
        disableCount,
        totalCount,
        scriptId,
        gradeId,
        script,
        courseDetails,
      } = toRefs(headerTable);

      watch(
        () => [calendar.value, semester.value, program.value, campus.value],
        () => {
          if (calendar.value && semester.value && program.value && campus.value) {
            let header = {
              calendar_id: calendar.value,
              semester_id: semester.value,
              program_id: program.value,
              campus_id: campus.value,
            };
            actionResponse.value = false;
            showCourses.value = false;
            overlay.value = true;
            getLecturerUpload(header)
              .then(() => {
                overlay.value = false;
                showCourses.value = true;

                let arr = Object.values(getters_lecturer_upload.value);
                arr.map((item) => {
                  item.forEach((obj) => {
                    totalCount.value += 1;
                    if (!obj.upload_script) {
                      disableCount.value += 1;
                    }
                  });
                });
              })
              .catch((e) => {
                overlay.value = false;
                showCourses.value = false;
                actionResponse.value = true;
                msgHeader.value = "Response";
                msgIcon.value = "mdi-close-circle";
                vm.proxy.$refs.courseHeader.reset();
                if (e.response.status === 403) {
                  msgBody.value = "This action is unauthorized";
                } else if (e.response.status === 423) {
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                }
              });
          }
        }
      );

      const searchProgram = debounce(() => {
        if (program.value != null || searchProgram.value != "undefined") {
          if (itemsProgram.value.length > 0) return;
          loadProgram.value = true;

          getProgram()
            .then(() => {
              itemsProgram.value = getters_program.value;
            })

            .finally(() => (loadProgram.value = false));
        }
      });

      const fetchCalendar = () => {
        iscalendarLoaded.value = true;
        if (getters_calendar.value.length > 0) {
          iscalendarLoaded.value = false;
          basket_calendar.value = getters_calendar.value;
          return;
        }
        getCalendar().then(() => {
          basket_calendar.value = getters_calendar.value;
          iscalendarLoaded.value = false;
        });
      };

      const fetchSemester = () => {
        issemesterLoaded.value = true;
        if (basket_semester.value.length > 0) {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
          return;
        }

        getSemester().then(() => {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
          return;
        });
      };

      const fetchCampus = () => {
        iscampusLoaded.value = true;
        if (getters_campus.value.length > 0) {
          iscampusLoaded.value = false;
          basket_campus.value = [...basket_campus.value];
        }

        getCampus().then(() => {
          if (getters_abilities.value.includes("all_campus_access")) {
            basket_campus.value = getters_campus.value;
          } else {
            basket_campus.value = getters_campus.value.filter(
              (campus) => campus.id === parseInt(user.value.campus[0].id)
            );
          }
          iscampusLoaded.value = false;
        });
      };

      const publishresultBtn = () => {
        overlay.value = true;
        const basket = selected.value.reduce((carry, current) => {
          if (current.is_published === false) {
            let obj = { id: current.id, is_published: current.is_published };
            carry.push(obj);
          }
          return carry;
        }, []);

        actionResponse.value = false;

        publishResult(basket)
          .then(() => {
            msgHeader.value = "Success";
            msgBody.value = "Selected Course(s) Published Successfully";
            msgIcon.value = "mdi-check-circle";
            color.value = "success";
            overlay.value = false;
          })
          .catch(() => {
            msgHeader.value = "Error";
            msgBody.value =
              "The Action Could Not Completed. Try Again Later or Call The System Administrator";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
          })
          .finally(() => {
            overlay.value = false;
            selected.value = [];
            actionResponse.value = true;
          });
      };

      const unpublishresultBtn = () => {
        overlay.value = true;
        const basket = selected.value.reduce((carry, current) => {
          if (current.is_published) {
            let obj = { id: current.id, is_published: !current.is_published };
            carry.push(obj);
          }
          return carry;
        }, []);
        actionResponse.value = false;

        unpublishResult(basket)
          .then(() => {
            msgHeader.value = "Success";
            msgBody.value = "Selected Course(s) Unpublished Successfully";
            msgIcon.value = "mdi-check-circle";
            color.value = "success";
          })
          .catch(() => {
            msgHeader.value = "Error";
            msgBody.value =
              "The Action Could Not Completed. Try Again Later or Call The System Administrator";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
          })
          .finally(() => {
            overlay.value = false;
            actionResponse.value = true;
            selected.value = [];
          });
      };

      const selectAllToggle = (props) => {
        if (selected.value.length != totalCount.value - disableCount.value) {
          selected.value = [];
          props.items.forEach((item) => {
            if (item.upload_script) selected.value.push(item);
          });
        } else {
          selected.value = [];
        }
      };

      const viewDetails = (course) => {
        setTimeout(() => {
          scriptId.value = course.id;
          gradeId.value = course.grade_id;
          script.value = true;
          courseDetails.value = course;
        }, 100);
      };

      provide("scriptId", scriptId);
      provide("gradeId", gradeId);
      provide("courseDetails", courseDetails);
      provide("color", color);

      return {
        ...toRefs(courseHeader),
        ...toRefs(headerTable),
        fetchCalendar,
        fetchSemester,
        fetchCampus,
        searchProgram,
        getters_lecturer_upload,
        publishresultBtn,
        unpublishresultBtn,
        selectAllToggle,
        viewDetails,
      };
    },
  };
</script>
<style scoped>
  .border:hover {
    border-left: 5px solid #1976d2 !important;
    cursor: pointer !important;
  }
</style>
